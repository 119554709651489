import React from "react"
import Layout from "../components/Layout"

export default function ThankYou() {
  return (
    <Layout>
      <section className={`container`}>
        <p>
          Thank you for getting in touch, I will get to you as soon as possible!
        </p>
      </section>
    </Layout>
  )
}
